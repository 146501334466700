<template>
  <div class=''>
    <div style="font-size: 35px">预约、问诊历史</div>
    <el-divider></el-divider>
    <!--    预约历史部分，数据格式和医生端看到的待完成预约一致-->
    <div style="font-size: 25px;text-align: center">预约历史</div>

    <el-table :data="appointHisString">
      <el-table-column label="预约医生" prop="realName"></el-table-column> <!--!!!漏传了一个医生姓名，这里应该是医生姓名！！！-->
      <el-table-column label="预约时间" prop="showTime"></el-table-column>

      <el-table-column label="症状备注" prop="symptoms">
        <template v-slot="scope11">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope11.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="就诊状态" prop="appointState">
        <template v-slot="scope12">
          <span v-if="scope12.row.inviteLink===null">
            <el-button type="primary" disabled>稍后就诊</el-button>
          </span>
          <span v-else>
            <el-button type="primary" @click="open1(scope12.row)">立即就诊</el-button>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="取消预约" prop="status">
        <template v-slot="scope13">
          <span v-if="new Date()<scope13.row.fixTime">
            <el-button type="danger" @click="appointCancel(scope13.row)">确定取消</el-button>
          </span>
          <span v-else>
            <el-button type="danger" disabled>不可取消</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-divider></el-divider>
    <div style="font-size: 25px;text-align: center">就诊历史</div>
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
    <!--    !!!目前绑定的是id，这个搜索框不能用-->

    <el-table :data="treatHisString.filter(data => !search || data.docId.toLowerCase().includes(search.toLowerCase()))"
              :default-sort = "{prop: 'diagnosisTime', order: 'descending'}">
      <el-table-column label="接诊医生" prop="docId"></el-table-column>
      <el-table-column label="就诊时间" prop="diagnosisTime" sortable></el-table-column>

      <el-table-column label="症状备注" prop="symptoms">
        <template v-slot="scope14">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope14.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊断结论" prop="diagnosisConclusion">
        <template v-slot="scope15">
          <el-popover
              title="诊断结论"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope15.row.diagnosisConclusion}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="医嘱备注" prop="remarks">
        <template v-slot="scope16">
          <el-popover
              title="医嘱备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope16.row.remarks}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊疗报告" prop="diagnosisConclusion">
        <template v-slot="scope13">
          <el-button type="primary"  @click="goReport(scope13.row)">查看报告</el-button>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import Cookies from "js-cookie";
import Vue from "vue";

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      userId: this.$store.state.personal_module.uid,
      userType: '个人',
      uname: this.$store.state.personal_module.username,

      search:'',

      appointHisString: [],
      treatHisString: [],
      roomType: false,
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    open1(arr){
      Cookies.set('PID',this.userId,{path:"/"})
      Cookies.set('RType',this.roomType,{path:"/"})
      window.open(arr.inviteLink,'_blank')
    },

    open2() {
      this.$notify({
        title: '请稍候',
        message: '医生正在创建远程诊室',
        type: 'warning'
      });
    },

    appointHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/listRecordByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看预约数据")
          console.log(data)
          this.appointHisString = data.recordOrderList
          Vue.set(this.appointHisString,'fixTime','')
          for (var t of this.appointHisString) {
            let xTime= t.dateTime.replace(/-/g, '/')
            let yTime= new Date(xTime)
            yTime.setDate(yTime.getDate()-1)
            let zTime= yTime
            t.fixTime= zTime
          }
          Vue.set(this.appointHisString,'showTime','')
          for (var it of this.appointHisString) {
            let xTime= it.dateTime.replace(/-/g, '/')
            let yTime= new Date(xTime)
            yTime.setDate(yTime.getDate())
            let zTime= yTime
            let nowDay = zTime.getDate();
            let nowMonth = zTime.getMonth()+1;
            let nowYear = zTime.getFullYear();
            if(zTime.getHours()==12){
              it.showTime= nowYear+"-"+nowMonth+"-"+nowDay+"上午"
            }
            if(zTime.getHours()==18){
              it.showTime= nowYear+"-"+nowMonth+"-"+nowDay+"下午"
            }
          }
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    treatHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/listDiagnosisByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看历史数据")
          console.log(data)
          this.treatHisString = data.recordDiagnosisEntity
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    appointCancel(arr) {
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/cancelOrder'),
        method: 'get',
        params: this.$http.adornParams({orderId: arr.orderId})
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看反馈的数据")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '取消成功',
            type: 'success'
          })
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    goReport(Obj){
      console.log("看看词条")
      console.log(Obj)
      this.$router.push({
        name: 'ViewTreatReport',
        query: {
          diagnosisId: Obj.diagnosisId,
          docId:Obj.docId,
        }
      })
    },

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.appointHistoryGet();
    this.treatHistoryGet();
  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
